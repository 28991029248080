define("discourse/plugins/retort/discourse/services/retort", ["exports", "@glimmer/tracking", "@ember/application", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _tracking, _application, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let RetortService = _exports.default = (_class = class RetortService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "messageBus", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "picker", _descriptor5, this);
      _initializerDefineProperty(this, "model", _descriptor6, this);
      _initializerDefineProperty(this, "widgets", _descriptor7, this);
    }
    init() {
      super.init(...arguments);
    }
    initBus() {
      if (this.model.id) {
        this.messageBus.unsubscribe(`/retort/topics/${this.model.id}`);
      }
      if (this.currentUser) {
        this.messageBus.subscribe(`/retort/topics/${this.model.id}`, _ref => {
          let {
            id,
            retorts
          } = _ref;
          const post = this.postFor(id);
          if (!post) {
            return;
          }
          post.setProperties({
            retorts
          });
          this.get(`widgets.${id}`).scheduleRerender();
        });
      }
    }
    postFor(id) {
      return (this.model.postStream.posts || []).find(p => p.id === id);
    }
    storeWidget(helper) {
      if (!this.widgets) {
        this.widgets = {};
      }
      this.set(`widgets.${helper.getModel().id}`, helper.widget);
    }
    updateRetort(_ref2, retort) {
      let {
        id
      } = _ref2;
      return (0, _ajax.ajax)(`/retorts/${id}.json`, {
        type: "POST",
        data: {
          retort
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    disabledCategories() {
      const categories = this.siteSettings.retort_disabled_categories.split("|");
      return categories.map(cat => cat.toLowerCase()).filter(Boolean);
    }
    disabledFor(postId) {
      if (!this.currentUser) {
        return true;
      }
      const post = this.postFor(postId);
      if (!post) {
        return true;
      }
      if (!post.topic.details.can_create_post) {
        return true;
      }
      if (post.topic.archived) {
        return true;
      }
      if (!post.topic.category) {
        return false;
      } else {
        const categoryName = post.topic.category.name;
        const disabledCategories = this.disabledCategories();
        return categoryName && disabledCategories.includes(categoryName.toString().toLowerCase());
      }
    }
    openPicker(post) {
      this.controller = (0, _application.getOwner)(this).lookup("controller:topic");
      this.set("picker.isActive", true);
      this.set("picker.post", post);
      this.set("controller.renderRetortPicker", true);
    }
    setPicker(picker) {
      this.set("picker", picker);
      this.picker.emojiSelected = retort => {
        this.updateRetort(this.picker.post, retort).then(() => {
          this.set("picker.isActive", false);
        });
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "messageBus", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "picker", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "widgets", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});